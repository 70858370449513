import React from 'react';
// import SEO from "../components/seo"
import { graphql } from 'gatsby';
import ProductList from '../components/ProductList';

const IndexPage = ({ data }) => {
  return (
    <>
      {/* <SEO title="Home" /> */}
      <ProductList data={data} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allShopifyProduct(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          id
          handle
          title
          description
          descriptionHtml
          productType
          variants {
            shopifyId
            title
            price
            availableForSale
          }
          images {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 910, maxHeight: 910) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
